<template>
  <v-container fluid>
    <h2>Mannschaftsführer/Trainer</h2>
    <v-row>
      <v-col cols="12">
        <base-edit-dialog-personsuche
          :value="getTrainer(0)"
          label="Trainer 1"
          id="null"
          clearable
          @input="({value}) => save(0, value)"
        />
      </v-col>
      <v-col cols="12">
        <base-edit-dialog-personsuche
          :value="getTrainer(1)"
          label="Trainer 2"
          id="null"
          clearable
          @input="({value}) => save(1, value)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'trainer',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    team: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  methods: {
    save (order, person) {
      if (!person) return this.clear(order)

      this.mutate({
        mutation: gql`mutation($id: UUID!, $team: UUID!, $person: UUID!, $order: Int!) {
          StbW2023WkTeamTrainerAdd(id: $id, team: $team, person: $person, order: $order) { _id }
        }`,
        variables: {
          id: this.id,
          team: this.team,
          person,
          order
        }
      })
    },
    clear (order) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $team: UUID!, $person: UUID!) {
          StbW2023WkTeamTrainerDelete(id: $id, team: $team, person: $person) { _id }
        }`,
        variables: {
          id: this.id,
          team: this.team,
          person: this.getTrainerId(order)
        }
      })
    },
    getTrainer (order) {
      const trainer = this.e?.teams
        ?.find(t => t.team._id === this.team)
        ?.trainer?.find(t => t.order === order)
      return trainer ? `${trainer.person.familyName}, ${trainer.person.givenName}` : null
    },
    getTrainerId (order) {
      const trainer = this.e?.teams
        ?.find(t => t.team._id === this.team)
        ?.trainer?.find(t => t.order === order)
      return trainer ? trainer.person._id : null
    }
  }
}
</script>

<style scoped>

</style>
